@import "../../scss/abstract/variables";
@import "../../scss/abstract/mixins";

.Header {
  background-color: $real-white;
  :global {
    .header-top {
      display: flex;
      align-items: center;
      width: $layout-width;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      padding: 8px 0;
      gap: 24px;
      @include mbp-down(md) {
        max-width: calc(100% - 48px);
        .logo {
          img {
            height: 40px;
            width: auto;
          }
        }
      }
      @include mbp-down(md) {
        max-width: calc(100% - 24px);
      }
      .header-contact {
        flex: 1;
        &:last-child {
          .contact-item {
            justify-content: flex-end;
          }
        }
        .contact-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: $black;
          text-decoration: none;
          gap: 10px;
          @include mbp-down(md) {
            display: none;
          }
          &:not(:last-child) {
            margin: 0 0 4px 0;
          }
          a {
            color: $black;
            text-decoration: none;
          }
        }
      }
      .hamburger-menu {
        display: none;
        @include mbp-down(md) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border: 0;
          outline: none;
          box-shadow: none;
          margin: 0 0 0 auto;
          color: $primary;
          background-color: transparent;
          path {
            fill: none;
            stroke-width: 1.5;
            transition: all 0.4s ease-in-out;
            stroke: currentcolor;
          }
          .line-2 {
            stroke-dasharray: 18 18;
            stroke-dashoffset: 0;
          }
          .line-1,
          .line-3 {
            stroke-dasharray: 19 19;
            stroke-dashoffset: -19;
          }
          &.menu-opened {
            .line-1,
            .line-3 {
              transform: translate(16px, 0);
              stroke-dashoffset: 0;
            }
            .line-2 {
              stroke-dashoffset: 18;
              transform: translate(9px, 0);
            }
          }
        }
      }
    }
    nav {
      background-color: $primary;
      position: relative;
      min-height: 4px;
    }
    .menu-list {
      display: flex;
      align-items: stretch;
      justify-content: center;
      width: $layout-width;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      @include mbp-down(md) {
        display: block;
        max-width: 100%;
        visibility: hidden;
        padding: 0;
        opacity: 0;
        transition: all 0.3s ease;
        transform: translate(0, -10px);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        overflow: hidden;
        &.menu-opened {
          z-index: 1;
          background-color: $primary;
          padding: 12px 0;
          visibility: visible;
          transform: translate(0, 0);
          opacity: 1;
          .menu-item {
            transform: translate(0, 0);
            opacity: 1;
          }
        }
      }
      .menu-item {
        margin: 0 1px;
        display: flex;
        align-items: stretch;
        @include mbp-down(md) {
          display: block;
          transform: translate(15px, 0);
          opacity: 0;
          transition: all 0.3s ease;
          transition-delay: 0s;
          &:nth-child(2) {
            transition-delay: 0.1s;
          }
          &:nth-child(3) {
            transition-delay: 0.2s;
          }
          &:nth-child(4) {
            transition-delay: 0.3s;
          }
          &:nth-child(5) {
            transition-delay: 0.4s;
          }
          &:nth-child(6) {
            transition-delay: 0.5s;
          }
          &:nth-child(7) {
            transition-delay: 0.6s;
          }
          &:nth-child(8) {
            transition-delay: 0.7s;
          }
          &:nth-child(9) {
            transition-delay: 0.8s;
          }
          &:nth-child(10) {
            transition-delay: 0.9s;
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        padding: 12px 15px;
        color: $white;
        text-decoration: none;
        font-size: 18px;
        line-height: 21px;
        transition: background-color 0.4s ease;
        @include mbp-down(md) {
          padding: 12px 24px;
        }
        @include mbp-down(sm) {
          padding: 12px;
        }
        &:hover {
          background-color: rgba($primary-dark, 0.5);
        }
      }
    }
  }
}
