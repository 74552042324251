@import "../abstract/mixins";

@mixin get-typo($name) {
  @if $name == h1 {
    font-weight: 500;
    font-size: 68px;
    line-height: 76px;
  } @else if $name == h2 {
    font-weight: 500;
    font-size: 46px;
    line-height: 54px;
  } @else if $name == h3 {
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
  } @else if $name == h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
  } @else if $name == h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  } @else if $name == h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
}

.is-h1 {
  @include get-typo(h1);
  @include mbp-down(sm) {
    &-on-mobile {
      @include get-typo(h1);
    }
  }
}
.is-h2 {
  @include get-typo(h2);
  @include mbp-down(sm) {
    &-on-mobile {
      @include get-typo(h2);
    }
  }
}
.is-h3 {
  @include get-typo(h3);
  @include mbp-down(sm) {
    &-on-mobile {
      @include get-typo(h3);
    }
  }
}
.is-h4 {
  @include get-typo(h4);
  @include mbp-down(sm) {
    &-on-mobile {
      @include get-typo(h4);
    }
  }
}
.is-h5 {
  @include get-typo(h5);
  @include mbp-down(sm) {
    &-on-mobile {
      @include get-typo(h5);
    }
  }
}
.is-h6 {
  @include get-typo(h6);
  @include mbp-down(sm) {
    &-on-mobile {
      @include get-typo(h6);
    }
  }
}

.bread-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.small {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.footnote {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

ul {
  &.list {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      padding: 0 0 0 36px;
      background-image: radial-gradient(circle, $primary 0px, $primary 3px, transparent 4px);
      background-position: 8px 8px;
      background-size: 8px 8px;
      background-repeat: no-repeat;
      list-style: none;
      &:not(:last-child) {
        margin: 0 0 12px 0;
      }
    }
  }
}
