// Brand colors
$primary-lightest: #d36e79;
$primary-lighter: #c74754;
$primary-light: #a8333f;
$primary: #812730;
$primary-dark: #5a1b22;
$primary-darker: #330f13;
$primary-darkest: #0c0304;

// Neutral colors
$real-white: #ffffff;
$white: #fcfcfc;
$neutral-1: #e8e6e8;
$neutral-2: #d3cfd4;
$neutral-3: #bfb9c0;
$neutral-4: #aaa2ac;
$neutral-5: #958b98;
$neutral-6: #817584;
$neutral-7: #6c5e70;
$neutral-8: #58475c;
$neutral-9: #433148;
$neutral-10: #2f1a34;
$black: #1a0420;
$real-black: #000000;

// Focus

$focus: #5bcdea;

// Layout
$layout-width: 1200px;
$gap: 24;
$gap-px: #{$gap}px;
