@mixin grid-generator($gutter, $col) {
  .row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: calc(100% + #{$gutter + "px"});
    margin: 0 -#{$gutter / 2 + "px"} -#{$gutter + "px"} -#{$gutter / 2 + "px"};
    & + .row {
      margin-top: #{$gutter + "px"};
    }
    .col-fill {
      flex: 1;
    }
    [class*="col"] {
      box-sizing: border-box;
      margin: 0 #{$gutter / 2 + "px"} #{$gutter + "px"} #{$gutter / 2 + "px"};
    }
    [class*="col"].col-break {
      width: 100%;
      height: 0;
      padding: 0;
      margin: 0;
      display: block;
    }
  }
  .col {
    flex: 1;
  }
  @for $i from $col to 0 {
    .col-#{$i} {
      width: calc(#{($i / $col) * 100 + "%"} - #{$gutter + "px"});
    }
  }
}

$media-breakpoints: (
  xs: 480px,
  sm: 768px,
  md: 1024px,
  lg: 1200px,
  xl: 1366px,
);

@mixin mbp-down($name) {
  @if (map-has-key($media-breakpoints, $name) == false) {
    // @error "Invalid breakpoint name: #{$name}";
    $breakpoint: $name;
    @media screen and (max-width: "#{$breakpoint}") {
      @content;
    }
  } @else {
    $breakpoint: map-get($media-breakpoints, $name);
    @media screen and (max-width: "#{$breakpoint - 1}") {
      @content;
    }
  }
}

@mixin mbp-up($name) {
  @if (map-has-key($media-breakpoints, $name) == false) {
    @error "Invalid breakpoint name: #{$name}";
  }

  $breakpoint: map-get($media-breakpoints, $name);
  @media screen and (min-width: "#{$breakpoint}") {
    @content;
  }
}

@mixin mbp-between($name-1, $name-2) {
  @if (map-has-key($media-breakpoints, $name-1) == false or map-has-key($media-breakpoints, $name-2) == false) {
    @error "Invalid breakpoint name: #{$name-1} or #{$name-1}";
  }

  $size-1: map-get($media-breakpoints, $name-1);
  $size-2: map-get($media-breakpoints, $name-2);
  $min: 0;
  $max: 0;

  @if $name-1 == $name-2 {
    @error "The two arguments cannot be the same: #{$name-1} and #{$name-2}";
  } @else if $size-1 < $size-2 {
    $min: $size-1;
    $max: $size-2 - 1;
  } @else {
    $min: $size-2;
    $max: $size-1 - 1;
  }

  @media screen and (min-width: "#{$min}") and (max-width: "#{$max}") {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
