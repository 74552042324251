@import "../../scss/abstract/variables";
@import "../../scss/abstract/mixins";

.Footer {
  background-color: $primary;
  color: $white;
  :global {
    .footer-top {
      text-align: center;
      padding: 24px;
      @include mbp-down(sm) {
        img {
          display: block;
          height: 60px;
          width: auto;
          margin: auto;
        }
      }
    }
    .social-links {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      margin: 0 0 24px 0;
      .social-item {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: $white;
          border-radius: 50%;
        }
      }
    }
    .menu-list {
      display: flex;
      align-items: stretch;
      justify-content: center;
      width: $layout-width;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      @include mbp-down(md) {
        max-width: calc(100% - 48px);
        display: block;
        text-align: center;
      }
      @include mbp-down(sm) {
        max-width: calc(100% - 24px);
      }
      .menu-item {
        margin: 0 1px;
      }
      a {
        display: block;
        padding: 12px 15px;
        color: $white;
        text-decoration: none;
        font-size: 18px;
        line-height: 21px;
      }
    }
    .copy-text {
      width: $layout-width;
      padding: 24px;
      text-align: center;
      margin: auto;
      max-width: calc(100% - 48px);
      a {
        display: inline-block;
        margin: 0 4px;
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
