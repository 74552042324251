@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap");
@import "./scss/abstract/variables";
@import "./scss/abstract/mixins";
@import "./scss/global/reset";
@import "./scss/global/typography";
@import "./scss/global/buttons";
@import "./scss/global/carousel";

body {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $neutral-1;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  > header + * {
    flex: 1;
  }
}

.content-pos {
  width: $layout-width;
  max-width: calc(100% - 60px);
  margin: 0 auto;
  @include mbp-down(md) {
    max-width: calc(100% - 48px);
  }
  @include mbp-down(sm) {
    max-width: calc(100% - 24px);
  }
}

@include grid-generator($gap, 12);
