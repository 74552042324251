@import "../../scss/abstract/variables";

.button {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.3s ease;
  outline: none;
  cursor: pointer;
  &:focus {
    box-shadow: 0px 0px 0px 2px $focus;
  }
  &.is-primary {
    color: $white;
    background-color: $primary;
    &:hover {
      background-color: $primary-light;
    }
    &:disabled {
      background-color: $neutral-6;
      pointer-events: none;
    }
  }
  &.is-secondary {
    color: $primary;
    background-color: $white;
    border: 1px solid currentColor;
    &:hover {
      color: $primary-light;
    }
    &:disabled {
      color: $neutral-6;
      pointer-events: none;
    }
  }
  &.is-link {
    color: $primary;
    &:hover {
      color: $primary-light;
    }
    &:disabled {
      color: $neutral-6;
      pointer-events: none;
    }
  }
  &.is-big {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding: 12px 24px;
  }
}
