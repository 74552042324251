@import "../abstract/variables";
@import "../abstract/mixins";

.carousel-root {
  outline: none;
}

.carousel {
  position: relative;
  width: 100%;
  * {
    box-sizing: border-box;
    outline: none;
  }
  .control-arrow,
  &.carousel-slider .control-arrow {
    transition: all 0.25s ease-in;
    opacity: 0.4;
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
  }
  .control-arrow:focus,
  .control-arrow:hover {
    opacity: 1;
  }
  .control-arrow:before,
  &.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: "";
  }
  .control-disabled.control-arrow {
    opacity: 0;
    cursor: inherit;
    display: none;
  }
  .control-prev {
    &.control-arrow {
      left: 0;
      &:before {
        border-right: 8px solid $white;
      }
    }
  }
  .control-next {
    &.control-arrow {
      right: 0;
      &:before {
        border-left: 8px solid $white;
      }
    }
  }
  .carousel {
    position: relative;
    img {
      width: 100%;
      display: inline-block;
      pointer-events: none;
    }
  }
  .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
  }
  .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
  }
  .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
  }
  .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid $black;
    padding: 2px;
    &:focus {
      border: 3px solid $neutral-3;
      outline: none;
    }
    &.selected,
    &:hover {
      border: 3px solid $neutral-9;
    }
    img {
      vertical-align: top;
    }
  }
  &.carousel-slider {
    position: relative;
    margin: 0;
    .control-arrow {
      top: 0;
      color: $white;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px;
      @include mbp-down(sm) {
        display: none;
      }
      &:hover {
        background: rgba($black, 0.2);
      }
    }
  }
  .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in;
    &.axis-horizontal {
      .slider {
        display: flex;
        .slide {
          flex-direction: column;
          flex-flow: column;
        }
      }
    }
    .axis-vertical {
      display: flex;
      .slider {
        flex-direction: column;
      }
    }
  }
  .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
    &.animated {
      transition: all 0.35s ease-in-out;
    }
  }
  .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background-color: rgba($black, 0.6);
    img {
      width: 100%;
      vertical-align: top;
      border: 0;
    }
    iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0;
    }
    .legend {
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: $black;
      color: $white;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      transition: opacity 0.35s ease-in-out;
    }
  }
  .control-dots {
    position: absolute;
    margin: 15px 0;
    top: 100%;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
    .dot {
      background: $white;
      border-radius: 5px;
      width: 24px;
      height: 10px;
      border: 2px solid $primary;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px;
      &.selected,
      &:hover {
        background-color: $primary;
      }
    }
  }
  .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba($black, 0.9);
    color: $white;
  }
  &:hover .slide .legend {
    opacity: 1;
  }
}
