@import "../../scss/abstract/variables";

.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  &::before {
    content: "";
    display: block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-image: radial-gradient(circle at center 3px, $primary 0px, $primary 3px, transparent 4px),
      radial-gradient(circle, $neutral-1 0px, $neutral-1 15px, transparent 16px),
      conic-gradient($neutral-1 0%, $neutral-1 10%, $primary 95%, $primary 100%);
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1, 1);
  }
  50% {
    transform: rotate(180deg) scale(1.1, 1.1);
  }
  100% {
    transform: rotate(360deg) scale(1, 1);
  }
}
